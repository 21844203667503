import { EventPublicationItem } from "modules/Events/events-types"
import type {
  GetCalculationEventsParams,
  GetPublicationEventsParams,
  GetScheduledPublicationEventsParams,
} from "typings/services"
import { api } from "./api"

const getCalculationEvents = async (options: GetCalculationEventsParams) => {
  const { data } = await api.events.getCalculations(options)

  return data
}

const getCalculationChildrenEvents = async (uuid: string) => {
  const { data } = await api.events.getCalculationChildren(uuid)

  return data
}

const getPublicationEvents = async (options: GetPublicationEventsParams) => {
  const { data } = await api.events.getPublications(options)

  return data
}

const getPublicationChildrenEvents = async (uuid: string) => {
  const { data } = await api.events.getPublicationsChildren(uuid)

  return data
}

const getScheduledPublicationEvents = async (options: GetScheduledPublicationEventsParams) => {
  const { data } = await api.events.getScheduledPublications(options)

  return data
}

const rescheduleCalculations = async (calculatorsIds: EventPublicationItem["calculatorId"][]) => {
  const { data } = await api.events.rescheduleCalculations(calculatorsIds)

  return data
}

const checkPublications = async () => {
  const { data } = await api.events.checkPublications()

  return data
}

export {
  getCalculationEvents,
  getCalculationChildrenEvents,
  getPublicationEvents,
  getPublicationChildrenEvents,
  getScheduledPublicationEvents,
  rescheduleCalculations,
  checkPublications,
}

import * as React from "react"
import { useMutation } from "react-query"
import { Box, Tab } from "@material-ui/core"
import { GridRowModel, GridSortModel } from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import FileSaver from "file-saver"

import {
  Header,
  Table,
  Tabs,
  TabPanel,
  IObjectTab,
  TableLoadingOverlay,
  DropzoneDialog,
  ButtonsGroup,
  TableToolbar,
  Button,
} from "components"
import { auditCustomerGroups, auditCustomers, getAllCustomers, getAllProductsWithCustomerGroups } from "services"
import { uiActions } from "store/ui-slice"
import { BULK_STATUS, TABLE } from "constants/bulk"
import { csvToJson, setAlertContent } from "utils/general"
import { IInputCSV, IBulkResponse } from "models/bulk"
import { updateCustomers } from "services/customers"
import { updateProductsByCustomersGroups as updateProductsByWholesalePrice } from "services/product"
import { getCsvContent } from "utils/csvHelper"
import { CUSTOMER_TABLE_HEADERS, CUSTOMER_GROUP_TABLE_HEADERS, AUDIT_TABLE_HEADERS } from "constants/tables"
import type { IProduct } from "modules/Products/models/product"
import { useAuditLog } from "hooks/use-audit"
import { CloudDownload } from "@material-ui/icons"
import { TooltipWrapperButton } from "components/Table/styles"
import { useCustomerList, useUploadStatus } from "hooks/use-customer-list"
import Loader from "components/Loader"
import { ENV } from "constants/env"
import { useHasReadWriteRole } from "utils/user"
import { getColumns as getByCompanyColumns } from "../constants/customersTable"
import { getColumns as getByGroupColumns } from "../constants/customerGroupsTable"
import { ManageCustomerDialog } from "../components"

const CustomersList = (): JSX.Element => {
  const userHasReadWriteRole = useHasReadWriteRole()

  const dispatch = useDispatch()

  const { getAuditLog: getCustomersAuditLog } = useAuditLog({
    getAuditLogService: auditCustomers,
    tableHeaders: AUDIT_TABLE_HEADERS,
    fileName: "Customers Audit Log",
  })

  const { getAuditLog: getCustomerGroupsAuditLog } = useAuditLog({
    getAuditLogService: auditCustomerGroups,
    tableHeaders: AUDIT_TABLE_HEADERS,
    fileName: "Customer Groups Audit Log",
  })

  const [
    [
      state,
      {
        customers,
        totalCustomers,
        isCustomersLoading,
        refetchCustomers,
        refetchCustomerGroups,
        onCustomersFilterChange,
        customerGroups,
        isCustomerGroupsLoading,
        onProductFilterChange,
        totalProducts,
        productByWholesaleRows,
        productByRetailRows,
        productsColumns,
        getProductsIsLoading,
      },
    ],
    { updater },
  ] = useCustomerList()

  const mutationKey = `update:${state.import}`

  const mutation = useMutation(
    mutationKey,
    (inputCSV: IInputCSV[]) =>
      state.import === "product-whole-sale-price"
        ? updateProductsByWholesalePrice(inputCSV)
        : updateCustomers(inputCSV, state.import === "customers"),
    {
      mutationKey,
      onMutate: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.PROCESSING)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
      onSuccess: (data: IBulkResponse) => {
        if (data) {
          const { children, severity } = setAlertContent(data.status)

          dispatch(
            uiActions.showNotification({
              children,
              severity,
            })
          )
        }
      },
      onError: () => {
        const { children, severity } = setAlertContent(BULK_STATUS.FAILED)

        dispatch(
          uiActions.showNotification({
            children,
            severity,
          })
        )
      },
    }
  )

  const { isUploading } = useUploadStatus(mutation.data?.id, state)

  const exportTable = async (isCustomerGroup?: boolean) => {
    dispatch(uiActions.showSpinner())
    const allCustomers = await getAllCustomers()
    let data: any[] | undefined = []
    let headers
    let fileName
    if (isCustomerGroup) {
      data = allCustomers
      headers = CUSTOMER_TABLE_HEADERS
      fileName = "List of Customers"
    } else {
      data = customerGroups
      headers = CUSTOMER_GROUP_TABLE_HEADERS
      fileName = "List of Customers Groups"
    }
    const csv = data && data.length > 0 ? getCsvContent(headers, data) : ""
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    dispatch(uiActions.hideSpinner())
    FileSaver.saveAs(blob, `${fileName}.csv`)
  }

  const getProductsWithCustomerCsvData = (items: IProduct[]) => {
    const data: any = []
    items.forEach((item: IProduct) => {
      if (item.customerGroups.length > 0) {
        let obj = { vsId: item.vsId }
        customerGroups?.forEach(cg => {
          const selectedCustomerGroup = item.customerGroups.find(pcg => pcg.id === cg.id)

          if (selectedCustomerGroup) {
            obj = {
              ...obj,
              [`${cg.name}_WHOLESALE`]: selectedCustomerGroup.wholesalePrice ?? "",
              [`${cg.name}_RETAIL`]: selectedCustomerGroup.retailPrice ?? "",
            }
          } else {
            obj = {
              ...obj,
              [`${cg.name}_WHOLESALE`]: "NOT_SELLING",
              [`${cg.name}_RETAIL`]: "NOT_SELLING",
            }
          }
        })
        data.push(obj)
      }
    })
    return data
  }

  const getProductByWholesalePriceHeaders = () => {
    const headersArray = [""]

    customerGroups?.forEach(cg => {
      headersArray.push(`${cg.name}_WHOLESALE`)
      headersArray.push(`${cg.name}_RETAIL`)
    })

    const csvColumns = headersArray.map((key: string) => {
      if (key === "") {
        return { header: key, accessor: "vsId" }
      }
      return { header: key, accessor: key }
    })

    return csvColumns
  }

  const exportProductByWholesalePrice = async () => {
    dispatch(uiActions.showSpinner())
    const items = await getAllProductsWithCustomerGroups()
    const headers = getProductByWholesalePriceHeaders()
    const csvData = getProductsWithCustomerCsvData(items)
    const csv = getCsvContent(headers, csvData)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const fileName = "List of Products by Wholesale Price"
    dispatch(uiActions.hideSpinner())
    FileSaver.saveAs(blob, `${fileName}.csv`)
  }

  const handleCustomerImport = () => {
    updater({
      import: "customers",
      modal: "dropzone",
    })
  }

  const handleCustomersGroupsImport = () => {
    updater({
      import: "customer-groups",
      modal: "dropzone",
    })
  }

  const handleProductsByWholesalePriceImport = () => {
    updater({
      import: "product-whole-sale-price",
      modal: "dropzone",
    })
  }

  const handleCloseModal = () => updater({ modal: undefined })

  const options = [
    {
      label: "Import Customers",
      action: () => handleCustomerImport(),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Import Customers Groups ",
      action: () => handleCustomersGroupsImport(),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Import Products by Wholesale Price",
      action: () => handleProductsByWholesalePriceImport(),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Export Customers",
      action: () => exportTable(true),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Export Customers Groups",
      action: () => exportTable(),
      disabled: !userHasReadWriteRole,
    },
    {
      label: "Export Products by Wholesale Price",
      action: () => exportProductByWholesalePrice(),
      disabled: !userHasReadWriteRole,
    },
  ]

  const [byCompanyColumns, byGroupColumns] = React.useMemo(
    () =>
      [getByCompanyColumns(updater, userHasReadWriteRole), getByGroupColumns(updater, userHasReadWriteRole)] as const,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const customerTabs: IObjectTab[] = [
    {
      id: 0,
      label: "By Company",
      children: (
        <Table
          rows={(customers?.content || []) as GridRowModel[]}
          columns={byCompanyColumns}
          loading={isCustomersLoading}
          pagination
          components={{
            LoadingOverlay: TableLoadingOverlay,
            Toolbar: () => (
              <Box display="flex">
                <TableToolbar showColumnsButton />
                <TooltipWrapperButton>
                  <Button variant="text" onClick={getCustomersAuditLog} icon={<CloudDownload />} size="small">
                    Download customers audit log
                  </Button>
                </TooltipWrapperButton>
              </Box>
            ),
          }}
          paginationMode="server"
          onPageChange={(newPage: number) => {
            updater({ customersPage: newPage })
          }}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
          sortingMode="server"
          sortModel={state.customersSortModel}
          onSortModelChange={(newModel: GridSortModel) => updater({ customersSortModel: newModel })}
          rowCount={totalCustomers}
          filterMode="server"
          onFilterModelChange={onCustomersFilterChange}
        />
      ),
    },
    {
      id: 1,
      label: "By Group",
      children: (
        <Table
          rows={customerGroups || []}
          columns={byGroupColumns}
          loading={isCustomerGroupsLoading}
          pagination
          components={{
            LoadingOverlay: TableLoadingOverlay,
            Toolbar: () => (
              <Box display="flex">
                <TableToolbar showColumnsButton />
                <TooltipWrapperButton>
                  <Button variant="text" onClick={getCustomerGroupsAuditLog} icon={<CloudDownload />} size="small">
                    Download customer groups audit log
                  </Button>
                </TooltipWrapperButton>
              </Box>
            ),
          }}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
        />
      ),
    },
    {
      id: 2,
      label: "By Wholesale Price",
      children: (
        <Table
          rows={productByWholesaleRows || []}
          columns={productsColumns || []}
          loading={getProductsIsLoading}
          pagination
          components={{
            LoadingOverlay: TableLoadingOverlay,
            Toolbar: () => <TableToolbar showColumnsButton />,
          }}
          paginationMode="server"
          onPageChange={(newPage: number) => {
            updater({ productsPage: newPage })
          }}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
          sortingMode="server"
          sortModel={state.productsSortModel}
          onSortModelChange={newModel => {
            updater({ productsSortModel: newModel })
          }}
          rowCount={totalProducts}
          filterMode="server"
          onFilterModelChange={onProductFilterChange}
          disableSelectionOnClick
        />
      ),
    },
    {
      id: 3,
      label: "By Retail Price",
      children: (
        <Table
          rows={productByRetailRows || []}
          columns={productsColumns || []}
          loading={getProductsIsLoading}
          pagination
          components={{
            LoadingOverlay: TableLoadingOverlay,
            Toolbar: () => <TableToolbar showColumnsButton />,
          }}
          paginationMode="server"
          onPageChange={(newPage: number) => {
            updater({ productsPage: newPage })
          }}
          autoPageSize={false}
          pageSize={ENV.PAGINATION.DEFAULT_PAGE_SIZE}
          sortingMode="server"
          sortModel={state.productsSortModel}
          onSortModelChange={newModel => {
            updater({ productsSortModel: newModel })
          }}
          rowCount={totalProducts}
          filterMode="server"
          onFilterModelChange={onProductFilterChange}
          disableSelectionOnClick
        />
      ),
    },
  ]

  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    updater({ tabValue: newValue })
  }

  const handleDropZone = async (files: File[]) => {
    const newState = { ...state }

    const mapCsvToJson = async () => {
      const textCustomersFile = await files[0].text()

      const json = csvToJson(
        // eslint-disable-next-line no-nested-ternary
        newState.import === "product-whole-sale-price"
          ? TABLE.PRODUCTS_BY_CUSTOMERS_GROUP
          : newState.import === "customers"
          ? TABLE.CUSTOMERS
          : TABLE.CUSTOMERS_GROUPS,
        textCustomersFile
      )

      newState.csvConverted = json
    }

    newState.customersFiles = files

    if (newState.customersFiles.length) {
      await mapCsvToJson()
    }

    newState.modal = undefined

    updater(newState)

    if (newState.csvConverted.length) {
      mutation.mutate(newState.csvConverted)
    }
  }

  const isProcessing = mutation.isLoading || isUploading

  return (
    <>
      {isProcessing && <Loader />}
      <Box>
        <Header
          title="Customers & Groups"
          actions={
            <>
              <ButtonsGroup options={options} disabled={isProcessing} />
              <Button
                id="create-customer"
                onClick={() => updater({ modal: "create-customer" })}
                isDisabled={Boolean(state.modal) || !userHasReadWriteRole}
              >
                Create customer
              </Button>
            </>
          }
        />
        <Tabs value={state.tabValue} onChange={handleTabChange}>
          {customerTabs.map((tab: IObjectTab) => [
            <Tab key={tab.id} icon={tab.icon} label={tab.label} disableRipple />,
          ])}
        </Tabs>
      </Box>
      <DropzoneDialog
        open={state.modal === "dropzone"}
        onSave={handleDropZone}
        acceptedFiles={["text/csv", ".csv"]}
        showPreviews
        onClose={handleCloseModal}
        initialFiles={state.customersFiles}
        dialogTitle={
          // eslint-disable-next-line no-nested-ternary
          state.import === "product-whole-sale-price"
            ? "Import Product by Wholesale Price"
            : state.import === "customers"
            ? "Import Customers"
            : "Import Customers Groups"
        }
        submitButtonText="Import"
      />
      {customerTabs.map((tab: IObjectTab) => [
        <TabPanel key={`panel-${tab.id}`} value={state.tabValue} index={tab.id}>
          {tab.children}
        </TabPanel>,
      ])}
      <ManageCustomerDialog
        data={state.data}
        modal={state.modal}
        customerGroups={customerGroups || []}
        handleClose={handleCloseModal}
        handleRefetch={() => {
          refetchCustomerGroups()

          refetchCustomers()

          updater({ modal: undefined })
        }}
      />
    </>
  )
}

export { CustomersList }

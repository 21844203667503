import { AxiosResponse } from "axios"

import type { IStep, IStepGroupRequest, IStepGroupResponse } from "typings/modules"
import type { IProduct, IProductData } from "modules/Products/models/product"
import type { ICompetitorsPrice, IInputCSV } from "modules/Competitors/models/competitor"
import type {
  IApiCalculationResultRequestBody,
  IAPICalculatorRequest,
  ICalculateEventRequest,
  ICalculateEventResponse,
  ICalculatorResultResponse,
  IMakeDebugRequestPayload,
  IPublicationData,
  IPublicationPriceParams,
  IPublicationResponse,
  IStepGroupResultRequest,
  IStepGroupType,
  IUpdateStepRequestPayload,
} from "modules/Rules-Pricing/models/calculator"
import type {
  EventCalculationChildrenResponse,
  EventCalculationResponse,
  EventPublicationResponse,
  EventPublicationChildrenResponse,
  EventScheduledPublicationResponse,
  EventPublicationItem,
  EventRescheduleCalculationResponse,
} from "modules/Events/events-types"
import type { IUserData, IAppserverApiResponse } from "modules/SignIn/models/user"
import type { ICreateCustomerRequest, ICustomer, IUpdateCustomerRequest } from "modules/Customers/models/customer"
import { buildUrlQueryParams } from "utils/general"
import {
  IAdjustment,
  IAdjustmentTable,
  IASCMarkupResponse,
  IASCShippingEstimateResponse,
  ILevelRequest,
  ILevelResponse,
  IUpdateAdjustmentParams,
  IUpdateMarkupParams,
  IUpdateShippingEstimateParams,
} from "modules/Parameters/models/parameters"
import { IAPISettingRequest } from "modules/Settings/models/settings"
import {
  ICreateSupplierRequest,
  ICreateSupplierResponse,
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
} from "modules/Suppliers/models/supplier"
import { ICustomerGroup, IUpdateCustomerGroupRequest } from "modules/Customers/models/customerGroup"
import { ENV } from "constants/env"
import {
  GetCalculationEventsParams,
  GetPublicationEventsParams,
  GetScheduledPublicationEventsParams,
} from "typings/services"
import { TabEvent } from "modules/Events/events-constants"
import { apiClient, apiClientAppserver, API_DOMAIN } from "./ApiServiceAxios"
import { IApiPaginationResponse, IQueryParams } from "../models/api"

export const api = {
  products: {
    get: (queryParams: IQueryParams): Promise<AxiosResponse> => {
      return apiClient.get(`/products?${buildUrlQueryParams(queryParams)}`)
    },
    getWithCustomerGroups: (queryParams: IQueryParams): Promise<AxiosResponse> => {
      return apiClient.get(`/products/customerGroups?${buildUrlQueryParams(queryParams)}`)
    },
    getAllWithCustomerGroups: (): Promise<AxiosResponse> => {
      return apiClient.get("/products/customerGroups")
    },
    getById: (productId: number): Promise<AxiosResponse<IProduct>> => {
      return apiClient.get(`/products/${productId}`)
    },
    getAll: (): Promise<AxiosResponse> => {
      return apiClient.get("/products")
    },
    create: (productData: IProductData): Promise<AxiosResponse> => {
      return apiClient.post(`/products`, productData)
    },
    update: (id: number, productData: IProductData): Promise<AxiosResponse> => {
      return apiClient.put(`/products/${id}`, productData)
    },
    updateProducts: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/products/bulk`, inputCSV)
    },
    updateProductsByCustomerGroups: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/products/bulkCustomerGroups`, inputCSV)
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/products/audit")
    },
  },
  suppliers: {
    get: (queryParams: IQueryParams): Promise<AxiosResponse> => {
      return apiClient.get(`/suppliers?${buildUrlQueryParams(queryParams)}`)
    },
    getAll: (): Promise<AxiosResponse> => {
      return apiClient.get("/suppliers")
    },
    create: (supplierPayload: ICreateSupplierRequest): Promise<AxiosResponse<ICreateSupplierResponse>> => {
      return apiClient.post<ICreateSupplierResponse>("/suppliers", supplierPayload)
    },
    update: (id: number, supplierPayload: IUpdateSupplierRequest): Promise<AxiosResponse<IUpdateSupplierResponse>> => {
      return apiClient.put<IUpdateSupplierResponse>(`/suppliers/${id}`, supplierPayload)
    },
    delete: (id: number): Promise<AxiosResponse<unknown>> => {
      return apiClient.delete<unknown>(`/suppliers/${id}`)
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/suppliers/audit")
    },
  },
  productTypes: {
    get: (): Promise<AxiosResponse> => {
      return apiClient.get("/productTypes")
    },
  },
  customerGroups: {
    get: (queryParams: IQueryParams): Promise<AxiosResponse> => {
      return apiClient.get(`/customerGroups?${buildUrlQueryParams(queryParams)}`)
    },
    getAll: (): Promise<AxiosResponse> => {
      return apiClient.get("/customerGroups")
    },
    updateCustomerGroups: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/customerGroups/bulk`, inputCSV)
    },
    updateProductsByCustomerGroups: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/customerGroups/bulkProducts`, inputCSV)
    },
    put: (id: number, customerGroupData: IUpdateCustomerGroupRequest): Promise<AxiosResponse<ICustomerGroup>> => {
      return apiClient.put(`/customerGroups/${id}`, customerGroupData)
    },
    delete: (customerGroupId: number): Promise<AxiosResponse<unknown>> => {
      return apiClient.delete(`/customerGroups/${customerGroupId}`)
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/customerGroups/audit")
    },
    getSubgroups: (customerGroupIds: number[]): Promise<AxiosResponse<string[]>> => {
      let url = "/customerGroups/subgroups"

      if (customerGroupIds.length > 0) {
        url += `?customerGroupIds=${customerGroupIds.join(",")}`
      }

      return apiClient.get(url)
    },
  },
  customers: {
    get: (queryParams: IQueryParams): Promise<AxiosResponse> => {
      return apiClient.get(`/customers?${buildUrlQueryParams(queryParams)}`)
    },
    getAll: (): Promise<AxiosResponse> => {
      return apiClient.get("/customers")
    },
    getById: (customerId: number): Promise<AxiosResponse> => {
      return apiClient.get(`/customers/${customerId}`)
    },
    create: (customerData: ICreateCustomerRequest): Promise<AxiosResponse<ICustomer>> => {
      return apiClient.post("/customers", customerData)
    },
    put: (id: number, customerData: IUpdateCustomerRequest): Promise<AxiosResponse<ICustomer>> => {
      return apiClient.put(`/customers/${id}`, customerData)
    },
    delete: (customerId: number): Promise<AxiosResponse<unknown>> => {
      return apiClient.delete(`/customers/${customerId}`)
    },
    updateCustomers: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/customers/bulk`, inputCSV)
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/customers/audit")
    },
  },
  calculators: {
    get: (): Promise<AxiosResponse> => {
      return apiClient.get<IStepGroupResponse[]>("/calculators")
    },
    getById: (calculatorId: number): Promise<AxiosResponse> => {
      return apiClient.get(`/calculators/${calculatorId}`)
    },
    put: (calculator: Partial<IAPICalculatorRequest>, calculatorId: number): Promise<AxiosResponse> => {
      return apiClient.put(`/calculators/${calculatorId}`, calculator)
    },
    post: (calculator: Partial<IAPICalculatorRequest>): Promise<AxiosResponse> => {
      return apiClient.post(`/calculators/`, calculator)
    },
    calculate: (calculators: ICalculateEventRequest) => {
      return apiClient.post<ICalculateEventResponse>(`/calculatorEvent/run`, calculators)
    },
    delete: (calculatorId: string): Promise<AxiosResponse> => {
      return apiClient.delete(`/calculators/${calculatorId}`)
    },
    getCalculationsById: (calculatorId: string): Promise<AxiosResponse> => {
      return apiClient.get(`/calculators/${calculatorId}/calculations`)
    },
    getCalculationResult: (
      queryParams: IQueryParams,
      calculatorId: string,
      calculationId?: number,
      switchKey?: IApiCalculationResultRequestBody["switchKey"]
    ): Promise<AxiosResponse> => {
      return apiClient.get<ICalculatorResultResponse>(
        `/results?${buildUrlQueryParams(queryParams)}&calculationId=${calculationId}${
          switchKey === "atypical" ? `&isAtypical=true` : ""
        }${switchKey === "error" ? `&isErrorPrice=true` : ""}`
      )
    },
    getAllCalculationsResult: (calculationId: number) => {
      const url = `/results?calculationId=${calculationId}`

      return apiClient.get(url)
    },
    exportAllCalculationResult: (calculationId: number) => {
      const url = `/results/export?calculationId=${calculationId}`

      return apiClient.get<string>(url)
    },
    updateCalculationResult: (overriddenResult: IStepGroupResultRequest): Promise<AxiosResponse> => {
      return apiClient.patch(`/results/${overriddenResult.id}`, overriddenResult)
    },
    duplicate: (calculatorId: number, calculatorName: string): Promise<AxiosResponse> => {
      return apiClient.post(`/calculators/${calculatorId}/duplicate`, {
        name: calculatorName,
      })
    },
    publish: (publicationData: IPublicationData): Promise<AxiosResponse> => {
      return apiClient.post("/publications", publicationData)
    },
    getPublishedPrices: (params: IPublicationPriceParams) => {
      const urlSearchParams = new URLSearchParams(params as any)

      const endpoint = `/publications?${urlSearchParams.toString()}`

      const response = apiClient.get<IApiPaginationResponse<IPublicationResponse>>(endpoint)

      return response
    },
    publishList: (publicationData: IPublicationData[]): Promise<AxiosResponse> => {
      return apiClient.post("/publications/list", publicationData)
    },
    debug: (values: IMakeDebugRequestPayload): Promise<AxiosResponse> => {
      const { calculatorId, productId, customerGroupId, showAll } = values

      const endpoint = `/calculators/${calculatorId}/debug/product/${productId}/customergroup/${customerGroupId}?showAll=${
        showAll ?? false
      }`

      return apiClient.get(endpoint)
    },
    exportStep: (calculatorId: string, stepId: string): Promise<AxiosResponse> => {
      return apiClient.get(`/calculators/${calculatorId}/step/${stepId}/calculate?page=0&size=10`)
    },
    exportStepCsv: (calculatorId: string, stepId: string) => {
      const stream = apiClient.get<string>(`/calculators/${calculatorId}/step/${stepId}/calculate`)

      return stream
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/calculators/audit")
    },
  },
  stepGroups: {
    getAll: async () => {
      const response = await apiClient.get<IStepGroupResponse[]>("/stepGroups?main=true")

      return response
    },
    getById: async (stepGroupId: number) => {
      const endpoint = `/stepGroups/${stepGroupId}`

      const response = await apiClient.get<IStepGroupResponse>(endpoint)

      return response
    },
    upsert: async (stepGroup: IStepGroupRequest, calculatorId: number) => {
      if (stepGroup.id) {
        const url = calculatorId
          ? `/stepGroups/${stepGroup.id}?calculatorId=${calculatorId}`
          : `/stepGroups/${stepGroup.id}`

        const response = await apiClient.put<IStepGroupResponse>(url, stepGroup)

        return response
      }

      const url = calculatorId ? `/stepGroups?calculatorId=${calculatorId}` : "/stepGroups"

      const response = await apiClient.post<IStepGroupResponse>(url, stepGroup)

      return response
    },
    delete: async (stepGroupId: number) => {
      const response = await apiClient.delete(`/stepGroups/${stepGroupId}`)

      return response
    },
  },
  rules: {
    get: (calculatorId?: number): Promise<AxiosResponse> => {
      return apiClient.get(
        `/rules${
          calculatorId
            ? `?calculatorId=${calculatorId}&operationType=ROUND_DOWN%2CMULTIPLICATION%2CSUBTRACTION%2CMAXIMUM%2CROUND_UP%2CSUM%2CDIVISION%2CCONDITIONAL%2CMINIMUM%2CAVERAGE`
            : ""
        }`
      )
    },
    getAllByStepId: (stepId: number): Promise<AxiosResponse> => {
      const endpoint = `${ENV.API.URL}/rules`

      const url = new URL(endpoint)

      url.searchParams.set("stepId", stepId.toString())

      url.searchParams.set(
        "operationType",
        "SUM,SUBTRACTION,DIVISION,MULTIPLICATION,CONDITIONAL,ROUND_UP,ROUND_DOWN,MAXIMUM,MINIMUM,AVERAGE"
      )

      const response = apiClient.get(url.toString())

      return response
    },
    delete: (stepId: number, ruleId: number): Promise<AxiosResponse> => {
      const response = apiClient.delete(`/steps/${stepId}/rule/${ruleId}`)

      return response
    },
  },
  steps: {
    getOne: (calculatorId?: number): Promise<AxiosResponse> => {
      let url = "/steps"

      if (calculatorId) {
        url += `?calculatorId=${calculatorId}`
      }

      return apiClient.get(url)
    },
    getAllById: (stepId: number): Promise<AxiosResponse<IStep>> => {
      const endpoint = `/steps/${stepId}`

      return apiClient.get(endpoint)
    },
    getAllByStepGroupId: (stepGroupId: number): Promise<AxiosResponse<IStep[]>> => {
      const endpoint = `/steps?stepGroupId=${stepGroupId}`

      return apiClient.get(endpoint)
    },
    getByImport: async (stepGroupId: number, stepId: number) => {
      const response = await apiClient.get<IStepGroupResponse>(`/stepGroups/${stepGroupId}/step/${stepId}`)

      return response
    },
    update: async (params: IUpdateStepRequestPayload) => {
      const { calculatorId, stepGroupId, step } = params

      const endpoint = `${API_DOMAIN}/steps/${step.id}`

      const url = new URL(endpoint)

      if (calculatorId) {
        url.searchParams.set("calculatorId", calculatorId.toString())
      }

      url.searchParams.set("stepGroupId", stepGroupId.toString())

      const response = await apiClient.put<IStep>(url.toString(), step)

      return response
    },
  },
  stepGroupTypes: {
    get: (calculatorId?: number): Promise<AxiosResponse> => {
      let url = "/stepGroupTypes"
      if (calculatorId) {
        url += `?calculatorId=${calculatorId}`
      }
      return apiClient.get(url)
    },
    getMasters: (calculatorId?: number) => {
      let url = "/stepGroupTypes?masterType=true"
      if (calculatorId) {
        url += `&calculatorId=${calculatorId}`
      }
      return apiClient.get<IStepGroupType[]>(url)
    },
  },
  systemObjects: {
    get: (tier?: string): Promise<AxiosResponse> => {
      let url = "/sysobjects"

      if (tier) {
        url += `?tier=${tier}`
      }

      return apiClient.get(url)
    },
  },
  user: {
    signIn: (userData: IUserData): Promise<AxiosResponse<IAppserverApiResponse>> => {
      return apiClientAppserver.post(`/user/rest/auth/v2/login`, userData)
    },
  },
  bulk: {
    geUploadStatus: (uuid: string) => {
      return apiClient.get(`/event/status/${uuid}`)
    },
  },
  competitors: {
    get: (): Promise<AxiosResponse> => {
      return apiClient.get(`/competitors`)
    },
    post: (): Promise<AxiosResponse> => {
      return apiClient.get(`/competitors`)
    },
    updateProducts: (inputCSV: IInputCSV[]): Promise<AxiosResponse> => {
      return apiClient.put(`/competitors/bulk`, inputCSV)
    },
    getProducts: (): Promise<AxiosResponse> => {
      return apiClient.get(`/competitors/products`)
    },
    geUploadStatus: (uuid?: string): Promise<AxiosResponse> => {
      return apiClient.get(`/event/status/${uuid}`)
    },
    deleteProduct: (id: string): Promise<AxiosResponse> => {
      return apiClient.delete(`/competitors/products/${id}`)
    },
    deleteCompetitor: (id: number): Promise<AxiosResponse> => {
      return apiClient.delete(`/competitors/${id}`)
    },
    audit: (): Promise<AxiosResponse> => {
      return apiClient.get("/competitors/audit")
    },
    updateProductPrice: (competitorProduct: ICompetitorsPrice): Promise<AxiosResponse> => {
      return apiClient.put(`/competitors/price`, competitorProduct)
    },
  },
  parameters: {
    getAdjustments: (): Promise<AxiosResponse<IAdjustmentTable[]>> => {
      return apiClient.get("/adjustments")
    },
    getLevels: (): Promise<AxiosResponse<ILevelResponse[]>> => {
      return apiClient.get("/levels")
    },
    getMarkups: (id: number): Promise<AxiosResponse<IASCMarkupResponse[]>> => {
      return apiClient.get(`suppliers/${id}/markups`)
    },
    getShipping: (id: number): Promise<AxiosResponse<IASCShippingEstimateResponse[]>> => {
      return apiClient.get(`suppliers/${id}/shipping`)
    },
    updateLevel: (levelId: number, levelData?: ILevelRequest): Promise<AxiosResponse> => {
      return apiClient.put(`/levels/${levelId}`, levelData)
    },
    updateMarkup: (params: IUpdateMarkupParams): Promise<AxiosResponse<IUpdateMarkupParams>> => {
      return apiClient.put(`markups/${params.id}`, params)
    },
    updateShipping: (params: IUpdateShippingEstimateParams): Promise<AxiosResponse<IUpdateShippingEstimateParams>> => {
      return apiClient.put(`shipping/${params.id}`, params)
    },
    updateAdjustment: (params: IUpdateAdjustmentParams): Promise<AxiosResponse<IAdjustment>> => {
      const { id, ...values } = params

      return apiClient.put(`adjustments/${id}`, values)
    },
  },
  settings: {
    get: (): Promise<AxiosResponse> => {
      return apiClient.get("/settings")
    },
    update: (settingId: string, settingData: IAPISettingRequest): Promise<AxiosResponse> => {
      return apiClient.put(`/settings/${settingId}`, settingData)
    },
  },
  events: {
    getCalculations: (queryParams: GetCalculationEventsParams) => {
      const params: Record<string, string> = {
        ...queryParams,
        page: queryParams.page.toString(),
        size: String(ENV.PAGINATION.DEFAULT_PAGE_SIZE),
      }

      const searchParams = new URLSearchParams(params)

      searchParams.append("type", TabEvent.CALCULATOR)

      const result = apiClient.get<EventCalculationResponse>(`/calculatorEvent?${searchParams.toString()}`)

      return result
    },
    getCalculationChildren: (uuid: string) => {
      const result = apiClient.get<EventCalculationChildrenResponse>(`/calculatorEvent/${uuid}`)

      return result
    },
    getPublications: (queryParams: GetPublicationEventsParams) => {
      const params: Record<string, string> = {
        ...queryParams,
        page: queryParams.page.toString(),
        size: String(ENV.PAGINATION.DEFAULT_PAGE_SIZE),
      }

      const searchParams = new URLSearchParams(params)

      searchParams.append("type", TabEvent.PUBLICATION)

      const result = apiClient.get<EventPublicationResponse>(`/publicationEvent?${searchParams.toString()}`)

      return result
    },
    getPublicationsChildren: (uuid: string) => {
      const result = apiClient.get<EventPublicationChildrenResponse>(`/publicationEvent/${uuid}`)

      return result
    },
    getScheduledPublications: (queryParams: GetScheduledPublicationEventsParams) => {
      const params: Record<string, string> = {
        ...queryParams,
        page: queryParams.page.toString(),
        size: String(ENV.PAGINATION.DEFAULT_PAGE_SIZE),
      }

      const searchParams = new URLSearchParams(params)

      const result = apiClient.get<EventScheduledPublicationResponse>(
        `/publicationEvent/scheduled?${searchParams.toString()}`
      )

      return result
    },
    rescheduleCalculations: (calculatorIds: EventPublicationItem["calculatorId"][]) => {
      const result = apiClient.post<EventRescheduleCalculationResponse>("/calculatorEvent/retrigger", {
        calculators: calculatorIds,
      })

      return result
    },
    checkPublications: () => {
      return apiClient.get("/event/check-events")
    },
  },
}

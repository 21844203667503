import * as React from "react"
import { getGridStringOperators, GridCellParams, GridColDef } from "@mui/x-data-grid"

import type { IStepGroupResponse } from "typings/modules"
import { StatusChip } from "components"
import { centerColumn, leftNonEditableColumn, leftNonEditableTooltipColumn } from "modules/TableGlobalStyles"
import { dateValueFormatter, productsAmountValueFormatter } from "utils/table"
import { CalculatorListActions } from "../containers/CalculatorListActions"
import { StepGroupListActions } from "../components/StepGroupListActions"

const CALCULATOR_LIST_COLUMNS: GridColDef[] = [
  {
    field: "name",
    headerName: "Calculator Name",
    type: "string",
    flex: 0.6,
    filterOperators: getGridStringOperators().filter(({ value }) => value === "contains"),
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "customerGroups",
    headerName: "Customer Groups",
    type: "string",
    flex: 0.6,
    sortable: false,
    filterable: false,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    flex: 0.6,
    filterable: false,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "productQty",
    headerName: "Applied to:",
    type: "string",
    flex: 0.4,
    sortable: false,
    filterable: false,
    ...leftNonEditableColumn,
    valueFormatter: productsAmountValueFormatter,
  },
  {
    field: "created",
    headerName: "Creation Date",
    type: "date",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
  },
  {
    field: "lastUpdate",
    headerName: "Update Date",
    type: "date",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    flex: 0.4,
    filterable: false,
    ...leftNonEditableColumn,
    renderCell: ({ row }: GridCellParams): JSX.Element => {
      return <StatusChip active={row.status === "ACTIVE"} />
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.3,
    filterable: false,
    ...centerColumn,
    renderCell: ({ id }: GridCellParams): JSX.Element => {
      return <CalculatorListActions id={id as number} />
    },
  },
]

const stepGroupColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "RuleSet Name",
    type: "string",
    flex: 0.6,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "type",
    headerName: "RuleSet Type",
    type: "string",
    flex: 0.6,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.3,
    ...centerColumn,
    renderCell: (cellParams: GridCellParams): JSX.Element => {
      const { row } = cellParams

      return <StepGroupListActions stepGroup={row as IStepGroupResponse} />
    },
  },
]

export { CALCULATOR_LIST_COLUMNS, stepGroupColumns }

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GridCellValue, GridColDef, GridValueFormatterParams, GridRenderCellParams } from "@mui/x-data-grid"
import React from "react"
import _ from "lodash"
import { format } from "date-fns"

import { mapNumberIntoCurrency } from "utils/parameters"
import { Paper, Popper, Typography, makeStyles } from "@material-ui/core"
import { renderPriceEditInputCell } from "./Parameters/components/PriceInput"
import { renderPercentEditInputCell } from "./Parameters/components/PercentInput"

interface IGridCellExpandProps {
  value: string
  width: number
}

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}))

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
}

const GridCellExpand = React.memo(function GridCellExpand(props: IGridCellExpandProps) {
  const { width, value } = props
  const wrapper = React.useRef<HTMLDivElement | null>(null)
  const cellDiv = React.useRef(null)
  const cellValue = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()
  const [showFullCell, setShowFullCell] = React.useState(false)
  const [showPopper, setShowPopper] = React.useState(false)

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false)
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <div ref={wrapper} className={classes.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width, marginLeft: -17 }}>
          <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
            <Typography variant="body1" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  )
})

const renderCellExpand = (params: GridRenderCellParams): JSX.Element => {
  return <GridCellExpand value={params.value ? params.value.toString() : ""} width={params.colDef.computedWidth} />
}

const leftNonEditableTooltipColumn: Omit<GridColDef, "field"> = {
  editable: false,
  align: "left",
  headerAlign: "left",
  renderCell: renderCellExpand,
}

const centerColumn: Omit<GridColDef, "field"> = {
  editable: false,
  sortable: false,
  align: "center",
  headerAlign: "center",
  filterable: false,
}

const leftNonEditableColumn: Omit<GridColDef, "field"> = {
  editable: false,
  align: "left",
  headerAlign: "left",
}

const leftEditableColumn: Omit<GridColDef, "field"> = {
  editable: true,
  align: "left",
  headerAlign: "left",
}

const formatPrice = (params: GridValueFormatterParams, keepValue: boolean, errorValue: string) => {
  const { value, row } = params

  const productVsId = row.productVsId as string

  /**
   * @description - Checking the product id is the only way to determine if the value is a price
   */
  const stringifiedValue = String(value)

  const isError = stringifiedValue.includes(productVsId) || (!_.isNil(value) && _.isNaN(Number(value)))

  if (isError) {
    return keepValue ? (value as string) : errorValue ?? "$0.00"
  }

  const valueFormatted = mapNumberIntoCurrency(value as number).toLocaleString()

  return value || value === 0 ? `$${valueFormatted}` : ""
}

const calculatorResultPriceFormatterParser: Omit<GridColDef, "field"> = {
  valueFormatter: (params: GridValueFormatterParams): string => {
    const value = formatPrice(params, true, null)

    return value
  },
  renderEditCell: renderPriceEditInputCell,
  valueParser: (value: GridCellValue) => {
    return Number(value)
  },
}

const priceFormatterParser: Omit<GridColDef, "field"> = {
  valueFormatter: (params: GridValueFormatterParams): string => {
    const value = formatPrice(params, false, null)

    return value
  },
  renderEditCell: renderPriceEditInputCell,
  valueParser: (value: GridCellValue) => {
    return Number(value)
  },
}

const customerGroupPriceFormatterParser: Omit<GridColDef, "field"> = {
  valueFormatter: (params: GridValueFormatterParams): string => {
    const value = formatPrice(params, false, "NOT SELLING")

    return value
  },
  renderEditCell: renderPriceEditInputCell,
  valueParser: (value: GridCellValue) => {
    return Number(value)
  },
}

const percentFormatterParser: Omit<GridColDef, "field"> = {
  valueFormatter: (params: GridValueFormatterParams): string => {
    const valueFormatted = Number((params.value as number) * 100).toLocaleString()
    return params.value ? `${valueFormatted}%` : ""
  },
  renderEditCell: renderPercentEditInputCell,
  valueParser: (value: GridCellValue) => Number(value),
}

const formatDate = (keyName: string, dateFormat = "yyyy-MM-dd HH:mm:ss") => {
  return (params: GridValueFormatterParams) => {
    const { row } = params

    let value = row[keyName] as string

    if (value) {
      value = value.includes("T") ? value : `${value}T00:00:00`

      const date = new Date(value)

      const formattedDate = format(date, dateFormat)

      return formattedDate
    }

    return ""
  }
}

export {
  centerColumn,
  leftNonEditableColumn,
  leftEditableColumn,
  leftNonEditableTooltipColumn,
  priceFormatterParser,
  customerGroupPriceFormatterParser,
  calculatorResultPriceFormatterParser,
  formatPrice,
  percentFormatterParser,
  formatDate,
}

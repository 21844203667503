export const PRODUCT_TABLE_HEADERS = [
  { header: "VD ID", accessor: "vsId" },
  { header: "Supplier ID", accessor: "supplierProdId" },
  { header: "UPC", accessor: "upc" },
  { header: "Manufacturer", accessor: "manufacturer" },
  { header: "Supplier", accessor: "supplierName" },
  { header: "Product", accessor: "name" },
  { header: "Strength/Size", accessor: "size" },
  { header: "Purachase Size", accessor: "purchaseSize" },
  { header: "Packaging", accessor: "packaging" },
  { header: "eCom Name", accessor: "ecomname" },
  { header: "Breakdown", accessor: "breakdown" },
  { header: "VS Cost", accessor: "vcost" },
  { header: "Vet List", accessor: "vetList" },
  { header: "Fee Strategy", accessor: "feeStrategy" },
  { header: "Banfield Fee Strategy", accessor: "banFeeStrategy" },
  { header: "Mars Fee Strategy", accessor: "marsFeeStrategy" },
  { header: "SRP Rule", accessor: "srpRuleName" },
  { header: "Exception", accessor: "exception" },
  { header: "MAP", accessor: "map" },
  { header: "Weight", accessor: "weight" },
  { header: "Species", accessor: "specieName" },
  { header: "Major Category", accessor: "majorCategoryName" },
  { header: "Category", accessor: "categoryName" },
  { header: "Rx Required", accessor: "rxrequired" },
  { header: "VIP Marker", accessor: "vipMarkerName" },
  { header: "Instant Rebate", accessor: "instantrebate" },
  { header: "Margin Focused Margin", accessor: "marginFocused" },
  { header: "Equine Fee", accessor: "equineFee" },
  { header: "Equine Retail Mark Up", accessor: "equineRetailMarkUp" },
]

export const ACTION_AUDIT_MAP: {
  [index: string]: string
} = {
  MOD: "Update",
  ADD: "Creation",
  DEL: "Delete",
}

export const PRODUCT_AUDIT_TABLE_HEADERS = [
  { header: "VD ID", accessor: "vsId" },
  { header: "Last Update", accessor: "lastUpdate" },
  { header: "Last Modified by", accessor: "lastModifiedBy" },
  { header: "Action", accessor: "action" },
  { header: "Fields", accessor: "fields" },
]

export const AUDIT_TABLE_HEADERS = [
  { header: "ID", accessor: "id" },
  { header: "Last Update", accessor: "lastUpdate" },
  { header: "Last Modified by", accessor: "lastModifiedBy" },
  { header: "Action", accessor: "action" },
  { header: "Fields", accessor: "fields" },
]

export const STEP_TABLE_HEADERS = [
  { header: "VD ID", accessor: "vsId" },
  { header: "Product", accessor: "name" },
  { header: "Result", accessor: "result" },
]

export const CUSTOMER_TABLE_HEADERS = [
  { header: "Site ID", accessor: "resellerId" },
  { header: "Name", accessor: "name" },
  { header: "Location", accessor: "location" },
  { header: "Customer Group", accessor: "customerGroupName" },
  { header: "Tier", accessor: "customerGroupSubgroup" },
]

export const CUSTOMER_GROUP_TABLE_HEADERS = [
  { header: "Group Full Name", accessor: "fullName" },
  { header: "Group Name", accessor: "name" },
  { header: "VS CustomerGroup ID", accessor: "vsGroupId" },
  { header: "Customer Type", accessor: "isPartner" },
]

import { IAPIResponse } from "models/api"
import { IAuditInformation } from "../../../models/audit"

export interface IStepGroupResult {
  id: number
  type: string
  price: string
  atypical: boolean
}

export interface ICustomerGroup {
  id: number
  name: string
  lastUpdate?: string
  created?: string
  fullName: string
  vsGroupId: number
  customersCount: number
  wholesalePrice?: number
  retailPrice?: number
  stepGroupResults: IStepGroupResult[]
  isPartner: boolean
}

export interface ISummarizedCustomerGroup {
  id: number
  name: string
}

export interface ICustomerGroupResponse extends IAPIResponse {
  content: ICustomerGroup[]
}

export interface IUpdateCustomerGroupRequest {
  name: string
  fullName: string
  vsGroupId: number
  isPartner: boolean
}

export interface ICustomerGroupsAuditInformation {
  createdBy: string | null
  created: string
  lastModifiedBy: string
  lastUpdate: string
  id: number
  name: string
  fullName: string
  vsGroupId: number
  subgroup: null | string
  active: boolean
  customers: string[]
  productCustomerGroups: string[]
  calculators: string[]
}

export type ICustomerGroupsAudit = [ICustomerGroupsAuditInformation, IAuditInformation, string, string[]]

export enum ProductPrices {
  RETAIL = "retailPrice",
  WHOLESALE = "wholesalePrice",
}

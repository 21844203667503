import * as React from "react"
import { Box } from "@material-ui/core"
import { GridCellParams, GridColDef } from "@mui/x-data-grid"
import { EditOutlined, DeleteOutlined } from "@material-ui/icons"

import { leftNonEditableColumn, leftNonEditableTooltipColumn } from "modules/TableGlobalStyles"
import { IconButton } from "components"
import { dateValueFormatter } from "utils/table"
import { IUseCustomerListState } from "hooks/use-customer-list"
import { ICustomerGroup, ICustomerGroupResponse } from "../models/customerGroup"

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Group ID",
    type: "string",
    flex: 0.3,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "fullName",
    headerName: "Customer Group Name",
    type: "string",
    flex: 0.7,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "customersCount",
    headerName: "Customers",
    type: "number",
    flex: 0.3,
    ...leftNonEditableTooltipColumn,
  },
  {
    field: "lastUpdate",
    headerName: "Last Modified",
    type: "date",
    flex: 0.4,
    ...leftNonEditableColumn,
    valueFormatter: dateValueFormatter,
  },
  {
    field: "isPartner",
    headerName: "Type",
    type: "string",
    flex: 0.4,
    ...leftNonEditableColumn,
    renderCell: (params: GridCellParams) => {
      const { row } = params

      const text = row.isPartner ? "RETAILPARTNER" : "RESELLER"

      return text
    },
  },
]

const getColumns = (
  updater: (newState: Partial<IUseCustomerListState>) => void,
  userHasReadWriteRole: boolean
): GridColDef[] => [
  ...columns,
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.25,
    editable: false,
    sortable: false,
    align: "center",
    headerAlign: "center",
    filterable: false,
    renderCell: ({ row }: GridCellParams): JSX.Element => {
      return (
        <Box>
          <IconButton
            color="inherit"
            size="small"
            icon={<EditOutlined />}
            onClick={() =>
              updater({
                modal: "update-customer-group",
                data: row as ICustomerGroup,
              })
            }
            isDisabled={!userHasReadWriteRole}
          />
          <IconButton
            color="inherit"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              updater({
                modal: "delete-customer-group",
                data: row as ICustomerGroup,
              })
            }
            isDisabled={!userHasReadWriteRole}
          />
        </Box>
      )
    },
  },
]

const stepGroupResults = [
  {
    id: 272638,
    type: "fee",
    price: "Attribute marginFocused in product has a null value",
    atypical: false,
  },
  {
    id: 272639,
    type: "retailPrice",
    price: "The product with vsId: 18371920EA must have at least a Competitor with a valid price",
    atypical: false,
  },
  {
    id: 272640,
    type: "wholesalePrice",
    price: "16.0",
    atypical: false,
  },
  {
    id: 272641,
    type: "acquisitionCost",
    price: "22.0",
    atypical: true,
  },
  {
    id: 272642,
    type: "vetPrice",
    price: "Attribute averagePrice in product has a null value",
    atypical: false,
  },
]

const paginatedRows: ICustomerGroupResponse = {
  content: [
    {
      id: 1,
      name: "BESTF",
      fullName: "BESTF description",
      customersCount: 345,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
    {
      id: 2,
      name: "TVC",
      fullName: "TVC description",
      customersCount: 345,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
    {
      id: 3,
      name: "PTHWY",
      fullName: "PTHWY description",
      customersCount: 678,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
    {
      id: 4,
      name: "NVA",
      fullName: "NVA description",
      customersCount: 451,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
    {
      id: 5,
      name: "VMG",
      fullName: "VMG description",
      customersCount: 20,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
    {
      id: 6,
      name: "AMVETG",
      fullName: "AMVETG description",
      customersCount: 20,
      vsGroupId: 1,
      stepGroupResults,
      isPartner: false,
    },
  ],
  empty: false,
  first: false,
  last: false,
  number: 1,
  size: 25,
  totalElements: 1324,
  totalPages: 53,
}

export { columns, getColumns, paginatedRows }
